@import "../../../styles/fonts.scss";
@import "../../../styles/_mixins.scss";

.container {
  display: flex;
}

.background {
  display: flex;
  flex: 1;

  &.imageLeftSide {
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  &.imageRightSide,
  &.imageLeftSide {
    .image {
      height: 100%;

      @media screen and (min-width: 768px) {
        width: 50%;
      }
    }

    @media screen and (min-width: 1920px) {
      align-items: center;
    }

    .content {
      @media screen and (min-width: 768px) {
        width: 50%;
      }
    }

    .content {
      @media screen and (max-width: 1279px) {
        padding: 48px 32px 24px;
      }

      @media screen and (min-width: 1280px) {
        padding: 56px 72px 64px;
        height: 100%;
      }
    }
  }

  &.imageRightSide {
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
    @media screen and (min-width: 768px) {
      flex-direction: row-reverse;
    }
  }

  &.imageTopSide {
    flex-direction: column;
  }

  &.imageBottomSide {
    flex-direction: column-reverse;
  }

  &.imageBottomSide,
  &.imageTopSide {
    .image {
      width: 100% !important;
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media screen and (max-width: 1279px) {
        padding: 48px 32px 24px;
      }

      @media screen and (min-width: 1280px) {
        padding: 56px 72px 64px;
      }

      @media screen and (min-width: 768px) {
        align-items: center;
      }

      & > * {
        @media screen and (min-width: 768px) {
          text-align: center;
          max-width: 500px;
        }
      }
    }
  }

  &.noImage {
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media screen and (min-width: 768px) {
        align-items: center;
      }

      & > * {
        @media screen and (min-width: 768px) {
          text-align: center;
          max-width: 500px;
        }
      }
    }
  }

  &.white {
    background-color: var(--white);
  }

  &.grey {
    background-color: var(--primary-back-light);
  }
}

.content {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    gap: 24px;
  }

  @media screen and (min-width: 768px) {
    justify-content: center;
    gap: 32px;
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    min-width: 260px;
  }
}

.title {
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 2.25rem;
  color: var(--black);

  @media screen and (min-width: 1920px) {
    max-width: 500px;
  }
}

.text {
  color: var(--black);
  line-height: 1.5;

  section {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      gap: 24px;
    }

    @media screen and (min-width: 768px) {
      gap: 32px;
    }
  }

  & * {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  h1 {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  h2 {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.6rem;
  }

  h3 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.6rem;
  }

  p {
    & + ul,
    & + ol {
      margin-top: 24px;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  ul,
  ol {
    margin-bottom: 24px;
    padding-left: 12px;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  strong {
    font-weight: bold !important;
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    width: fit-content;
    color: var(--primary);
    transition: var(--transition);

    &:hover {
      color: var(--primary-dark);
    }
  }

  button {
    width: fit-content;
    min-height: 40px;
    padding: 0 24px;
    font-weight: 500 !important;
    background-color: var(--primary);
    color: var(--white);
    outline: none;
    border: none;
    transition: var(--transition);

    &:hover {
      background-color: var(--primary-dark);
    }

    @media screen and (max-width: 1059px) {
      @include apply-font(preformatted, mobile, regular);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(preformatted, desktop, regular);
    }
  }
}

.image {
  object-fit: cover;

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    max-width: 100%;
  }
}

.info {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    gap: 24px;
  }

  @media screen and (min-width: 768px) {
    gap: 32px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 500px;
  }
}
